const downloadLinks = [
    'https://drive.google.com/uc?export=download&id=1dEPE2FclrmgbYpHaPGX4FnfGQe2CBs9a', //1
    'https://drive.google.com/uc?export=download&id=1P0UDoRF-hLIzFWonFiXcgl19X45x300E', //2
    'https://drive.google.com/uc?export=download&id=1elu-rE9_xbnKJotIK6a_82_v7gu5LaXf', //3
    'https://drive.google.com/uc?export=download&id=14Q6BUPAQ28TkE9AtOy3CfKkDwTT83RlK', //4
    'https://drive.google.com/uc?export=download&id=172TpQy4zqtnv3-5lih_hhEQ1ec82E4jy', //5
    'https://drive.google.com/uc?export=download&id=1whscLvirbCgu1aWkfqIxTnneokDCSQA_', //6
    'https://drive.google.com/uc?export=download&id=1iZ4szDuddl2N5_1-j6VEj71D9Vvt0tli', //7
    'https://drive.google.com/uc?export=download&id=1ol23Z2eaGPlKZJSY8FWn_yEGp2bm0i5B', //8
    'https://drive.google.com/uc?export=download&id=1Ek3xJTVab7N5DBHjeNVLo5GTZ6rWUek1', //9
    'https://drive.google.com/uc?export=download&id=1QGKIk3nyoSsSWIJ3Ivly2f9rd6TVdBVS', //10
    'https://drive.google.com/uc?export=download&id=1gLhz_XW-DAyWBOF97_ZE2ufxnVudNz3b', //11
    'https://drive.google.com/uc?export=download&id=1p9YV3gZZ_CToE6unjuSfDxVEzXW72OAB', //12
    'https://drive.google.com/uc?export=download&id=1-o9DUGAKY84p2QqGwQ7dLJ4TqRrDffL4', //13
    'https://drive.google.com/uc?export=download&id=1_gODYgd-P8KxY5lVpaEbXEvimG2cOF56', //14
    'https://drive.google.com/uc?export=download&id=1pTN3FzmPra5yD6Ix8sFOLkjXGbFr98eI', //15
    'https://drive.google.com/uc?export=download&id=1LcQ7250OT1I7mOVSUUTEyJ4LeZGan27H', //16
    'https://drive.google.com/uc?export=download&id=1VIWCopFz6BZI-h57kXEzDsXxhJZ9cAm4', //17
    'https://drive.google.com/uc?export=download&id=1JnRMgp7jy9PMX62I3q5zeP_pGg4jUuxd', //18
    'https://drive.google.com/uc?export=download&id=1CbXQSpWLMTencpJMTEl17EnVd4mEmWkL', //19                                                                                                                                       ,
    'https://drive.google.com/uc?export=download&id=1YcNdZDBASJxW0442jPX2MYOnpTIZvROI', //20
    'https://drive.google.com/uc?export=download&id=1byZhbMDiASpkYLk7Vin2mGkOZsBRcBhE', //21
    'https://drive.google.com/uc?export=download&id=1J8-oueAy2UuT29uq0F01NUEbp5Kn0MMO', //22
    'https://drive.google.com/uc?export=download&id=1uNIW9uqcCEVS3780rWZANqRdtFvRsf6a', //23
    'https://drive.google.com/uc?export=download&id=1BpkJJ0oipqkrE37kjQH4YuMfcr4dgRGY', //24
    'https://drive.google.com/uc?export=download&id=1gDVq4pNjI-136XSPJHuMww0ZXLNhrf_s', //25
  ];
  

export const files = [
  {
    id: 1,
    name: "NEET ANSWER KEY & SOLUTION - XI FULL TEST-1",
    description: "PAPER CODE :- MOCK TEST-1 CLASS-XI",
    driveLink: downloadLinks[0],
    code: "MEDJEEX100",
  },
  {
    id: 2,
    name: "NEET ANSWER KEY & SOLUTION - XI FULL TEST-3",
    description: "PAPER CODE :- MOCK TEST-3 CLASS-XI",
    driveLink: downloadLinks[1],
    code: "MEDJEEX101",
  },
  {
    id: 3,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-1",
    description: "PAPER CODE :- PART TEST-1 CLASS-XI",
    driveLink: downloadLinks[2],
    code: "MEDJEEX102",
  },
  {
    id: 4,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-2",
    description: "PAPER CODE :- PART TEST-2 CLASS-XI",
    driveLink: downloadLinks[3],
    code: "MEDJEEX103",
  },
  {
    id: 5,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-3",
    description: "PAPER CODE :- PART TEST-3 CLASS-XI",
    driveLink: downloadLinks[4],
    code: "MEDJEEX104",
  },
  {
    id: 6,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-4",
    description: "PAPER CODE :- PART TEST-4 CLASS-XI",
    driveLink: downloadLinks[5],
    code: "MEDJEEX105",
  },
  {
    id: 7,
    name: "NEET ANSWER KEY & SOLUTION - XI PART TEST-5",
    description: "PAPER CODE :- PART TEST-5 CLASS-XI",
    driveLink: downloadLinks[6],
    code: "MEDJEEX106",
  },
  {
    id: 8,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-1",
    description: "PAPER CODE :- FULL TEST-1 CLASS-XI+XII",
    driveLink: downloadLinks[7],
    code: "MEDJEEX107",
  },
  {
    id: 9,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-2",
    description: "PAPER CODE :- FULL TEST-2 CLASS-XI+XII",
    driveLink: downloadLinks[8],
    code: "MEDJEEX108",
  },
  {
    id: 10,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-3",
    description: "PAPER CODE :- FULL TEST-3 CLASS-XI+XII",
    driveLink: downloadLinks[9],
    code: "MEDJEEX109",
  },
  {
    id: 11,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-4",
    description: "PAPER CODE :- FULL TEST-4 CLASS-XI+XII",
    driveLink: downloadLinks[10],
    code: "MEDJEEX110",
  },
  {
    id: 12,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-5",
    description: "PAPER CODE :- FULL TEST-5 CLASS-XI+XII",
    driveLink: downloadLinks[11],
    code: "MEDJEEX111",
  },
  {
    id: 13,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-6",
    description: "PAPER CODE :- FULL TEST-6 CLASS-XI+XII",
    driveLink: downloadLinks[12],
    code: "MEDJEEX112",
  },
  {
    id: 14,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-7",
    description: "PAPER CODE :- FULL TEST-7 CLASS-XI+XII",
    driveLink: downloadLinks[13],
    code: "MEDJEEX113",
  },
  {
    id: 15,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-8",
    description: "PAPER CODE :- FULL TEST-8 CLASS-XI+XII",
    driveLink: downloadLinks[14],
    code: "MEDJEEX114",
  },
  {
    id: 16,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-9",
    description: "PAPER CODE :- FULL TEST-9 CLASS-XI+XII",
    driveLink: downloadLinks[15],
    code: "MEDJEEX115",
  },
  {
    id: 17,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-10",
    description: "PAPER CODE :- FULL TEST-10 CLASS-XI+XII",
    driveLink: downloadLinks[16],
    code: "MEDJEEX116",
  },
  {
    id: 18,
    name: "NEET ANSWER KEY & SOLUTION - XI-XII FULL TEST-11",
    description: "PAPER CODE :- FULL TEST-11 CLASS-XI+XII",
    driveLink: downloadLinks[17],
    code: "MEDJEEX117",
  },
  {
    id: 19,
    name: "NEET ANSWER KEY & SOLUTION - XII FULL! TEST-2",
    description: "PAPER CODE :- FULL TEST-2 CLASS-XII",
    driveLink: downloadLinks[18],
    code: "MEDJEEX118",
  },
  {
    id: 20,
    name: "NEET ANSWER KEY & SOLUTION - XII FULL TEST-4",
    description: "PAPER CODE :- FULL TEST-4 CLASS-XII",
    driveLink: downloadLinks[19],
    code: "MEDJEEX119",
  },
  {
    id: 21,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-1",
    description: "PAPER CODE :- PART TEST-1 CLASS-XII",
    driveLink: downloadLinks[20],
    code: "MEDJEEX120",
  },
  {
    id: 22,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-2",
    description: "PAPER CODE :- PART TEST-2 CLASS-XII",
    driveLink: downloadLinks[21],
    code: "MEDJEEX121",
  },
  {
    id: 23,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-3",
    description: "PAPER CODE :- PART TEST-3 CLASS-XII",
    driveLink: downloadLinks[22],
    code: "MEDJEEX122",
  },
  {
    id: 24,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-4",
    description: "PAPER CODE :- PART TEST-4 CLASS-XII",
    driveLink: downloadLinks[23],
    code: "MEDJEEX123",
  },
  {
    id: 25,
    name: "NEET ANSWER KEY & SOLUTION - XII PART TEST-5",
    description: "PAPER CODE :- PART TEST-5 CLASS-XII",
    driveLink: downloadLinks[24],
    code: "MEDJEEX124",
  },
];
